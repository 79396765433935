<template>
    <o-data-lookup :data-object="dsObjectTypes" :whereClause="getWhereClause" reloadOnWhereClauseChange>        
        
        <template #toolbarActions>
            <div class="form-check" v-if="props.IdPath">
                <input class="form-check-input" id="otLookup_RestrictToContext" type="checkbox" v-model="restrictedToContext" @click="toggleContext" >
                <label class="form-check-label" for="otLookup_RestrictToContext">{{ $t("Restrict to Context") }}</label>
            </div>
            <slot name="toolbarActions"></slot>
        </template>
        <template #target="scope">
            <slot v-if="$slots.target" name="target" v-bind="scope"></slot>
            <span v-else-if="$slots.objecttype" :ref="scope.target" style="cursor: pointer;">
                <slot name="objecttype"></slot>
            </span>
        </template>
        
        
        <o-column field="ID" width="80"></o-column>
        <o-column field="Name" width="300"></o-column>

    </o-data-lookup>
</template>

<script setup>
    import { computed, ref } from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject'

    const restrictedToContext = ref(true);
    const props = defineProps({
        is: String,
        IdPath: String,      
        textInputValue: String,
        textInput: Boolean
    });

    const dsObjectTypes = getOrCreateDataObject({
        id: 'dsObjectTypes' + crypto.randomUUID(),
        viewName: 'aviw_Assets_ObjectTypesLookup',
        maxRecords: 25,
        whereClause: "",
        loadRecents: false,
        distinctRows: true,
        fields:
             [{name: "ID", type: "number" },
             {name: "Name", type: "string", sortOrder: 1, sortDirection: "asc" }]
    });

    
    const getWhereClause = computed( () => {
        var vFilter = [];

        if (props.IdPath && restrictedToContext.value) {
            vFilter.push("'" + props.IdPath + "' LIKE IdPath + '%'");
        } 

        return vFilter.join(" AND ");
    });
        

    function toggleContext(e,o) {
        restrictedToContext.value = !restrictedToContext.value;
    }
    
</script>